import React, {useEffect, useState} from 'react';
import {
    GET_COLLECTION_DATA,
    SEARCH_COLLECTION_URL
} from '../../../api';
import 'datatables.net';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt';
import 'datatables.net-editor';
import 'components-jqueryui';
import $ from "jquery";
import 'select2';
import {Link} from "react-router-dom";
import cogoToast from "cogo-toast";
import { region, state, city, suburb } from "api/app";
import {capitalize} from "utils/capitalize";

const Region = () => {

    document.title = "Region";

    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {

        let table = $('#region').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search suburb/area',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Country Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: (data, callback) => {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "suburb",
                        fieldname: "name",
                        pageSize: data.start,
                        format: 'json',
                        sort: {name: 1},
                        pageIndex: (data.length + data.start) / data.length,
                        populate: {
                            path: 'city',
                            model: 'city',
                            populate: {
                                path: 'state',
                                model: 'state',
                                populate: {
                                    path: 'country',
                                    model: 'country',
                                    select: 'name'
                                }
                            }
                        }
                    },
                    success : function(res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            select: {
                style:    'os',
                selector: 'td:first-child'
            },
            "columns": [
                {"data": "raw", "className": "editable"},
                {"data": "raw", "className": "editable"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.active ? capitalize(data.name) : `<span class="tx-danger">${capitalize(data.name)}</span>`;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return capitalize(data.city.name)
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return capitalize(data.city.state.name)
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return capitalize(data.city.state.country.name)
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link activate_area"><i class="fa fa-power-off"></i> ${data.active ? "Deactivate" : "Activate"}</a> <a href="#" class="nav-link delete_suburb"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 4
                }
            ]
        })

        table.on('click', '.delete_suburb', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, ${extract_td.raw.name} area?`);
            $('#option-suburb').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw.city.state.country._id,
                    state_id: extract_td.raw.city.state._id,
                    city_id: extract_td.raw.city._id,
                    suburb_id: extract_td.raw._id
                }

                suburb("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        reloadTable();
                        cogoToast.success(result.payload, {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });


        table.on('click', '.activate_area', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to ${extract_td.raw.active ? "deactivate" : "activate"}, ${capitalize(extract_td.raw.name)} area?`);

            $('#option-suburb').unbind("click").click(function(){
                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                    active: `${!extract_td.raw.active}`
                }

                suburb("put", data).then((result) => {
                    hide();
                    if(!result.error) {
                        reloadTable();
                        cogoToast.success(result.payload, {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });
        // table.on('click', 'td.editable', function (e) {
        //     e.preventDefault();
        //
        //     if (e.target.localName !== 'input') {
        //         let row = e.target._DT_CellIndex.row;
        //         let col = e.target._DT_CellIndex.column;
        //
        //         if (!e.target.children.length) {
        //             e.target.innerHTML = `<input id=${row}-${col} type=text class=editor value=${e.target.innerHTML}>`
        //         }
        //     }
        // });

        // table.on("mouseleave", "td.editable", function (e) {
        //     e.preventDefault()
        //     if (e.target.localName !== "input") {
        //         let row = e.target._DT_CellIndex.row;
        //         let col = e.target._DT_CellIndex.column;
        //         table.cell(row, col).data(e.target.firstElementChild.value);
        //         table.draw() // up to you
        //     }
        //     else { // forces write when there is an event delay
        //         let [row, col] = e.target.id.split("-");
        //         table.cell(Number(row), Number(col)).data(e.target.value);
        //     }
        //     table.draw()
        // })

        return () => {
            table.destroy();
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let id = $('#country-select2').val()[0];
        let state_id = $('#state-select2').val()[0];
        let city_id = $('#city-select2').val()[0];
        let name = $('#suburb').val();

        setStateReady(true);

        let data = {id, state_id, city_id, name};

        region("post", data).then((result) => {
            setStateReady(false);
            if(!result.error) {
                cogoToast.success(result.payload, {position: "top-right", hideAfter: 5});
                resetForm("modalRegion", "region_form");
                reloadTable();
            }
        });
    };

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const handleShowModal = () => {
        setEditForm(false);
        $('#region_form').parsley();
        $(".editable").popover({
            html: true,
            sanitize: false
        });
        $('body').on('click', function (e) {
            dismissPopover(e);
        });
        initializeSearch();
        $("#modalRegion").modal({ backdrop: 'static', focus: false, show: true });
    };

    const initializeSearch = () => {
        let city_select = $('#city-select2');
        let state_select = $('#state-select2');
        let modalRegion = $('#modalRegion');
        state_select.prop("disabled", true);
        city_select.prop("disabled", true);
        $('#suburb').prop("disabled", true);

        $('#country-select2').select2({
            placeholder: 'Select country or type name',
            allowClear: true,
            maximumSelectionLength: 1,
            dropdownParent: modalRegion,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization" : token,
                },
                data: function (query) {
                    return {
                        query: query,
                        collection: "country"
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data, function (item) {
                            return {
                                text: capitalize(item.name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function () {
            $('#state-select2').prop("disabled", false);
        }).on('select2:unselect', function () {
            $('#state-select2').prop("disabled", true).val(null).trigger("change");
            $('#city-select2').prop("disabled", true).val(null).trigger("change");
            $('#suburb').prop("disabled", true).val(null).trigger("change");
        });

        state_select.select2({
            placeholder: 'Select state or type name',
            allowClear: true,
            maximumSelectionLength: 1,
            dropdownParent: modalRegion,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization" : token,
                },
                data: function (query) {
                    return {
                        query: query,
                        collection: "state",
                        filter: {
                            country: $('#country-select2').val()[0]
                        }
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data, function (item) {
                            return {
                                text: capitalize(item.name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function () {
            $('#city-select2').prop("disabled", false);
        }).on('select2:unselect', function () {
            $('#city-select2').prop("disabled", true).val(null).trigger("change");
            $('#suburb').prop("disabled", true).val(null).trigger("change");
        });

        city_select.select2({
            placeholder: 'Select city or type name',
            allowClear: true,
            maximumSelectionLength: 1,
            dropdownParent: modalRegion,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization" : token,
                },
                data: function (query) {
                    return {
                        query: query,
                        collection: "city",
                        filter: {
                            state: $('#state-select2').val()[0]
                        }
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data, function (item) {
                            return {
                                text: capitalize(item.name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function () {
            $('#suburb').prop("disabled", false);
        }).on('select2:unselect', function () {
            $('#suburb').prop("disabled", true).val(null).trigger("change");
        });
    }

    const handleStateSubmit = () => {
        let state_form = $('#state_form');
        let state_btn = $('#state_form button');
        let country_id = $('#country-select2').val()[0];
        let caption = $('#state_form #helpTip');

        state_form.parsley();
        country_id ? state_btn.prop("disabled", false) : state_btn.prop("disabled", true);
        country_id ? caption.text("Enter unique name to avoid duplicate data.") : caption.text("Please, select country before you add data.").addClass("tx-danger");

        state_form.on('submit', function(e){
            e.preventDefault();

            let id = country_id;
            let name = $('#state_name').val();

            let data = {id, name};

            $("#state_form button").text("Please Wait").prop("disabled", true).prepend('<span class="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>');
            state("post", data).then((result) => {
                $("#state_form button").text("Submit").prop("disabled", false);
                if(!result.error) {
                    $("[data-toggle='popover']").popover('hide');
                    cogoToast.success(result.payload, {position: "top-right", hideAfter: 5});
                }
            });
        });
    }

    const handleCitySubmit = () => {
        let city_form = $('#city_form');
        let city_btn = $('#city_form button');
        let state_id = $('#state-select2').val()[0];
        let caption = $('#city_form #helpTip');

        city_form.parsley();
        state_id ? city_btn.prop("disabled", false) : city_btn.prop("disabled", true);
        state_id ? caption.text("Enter unique name to avoid duplicate data.") : caption.text("Please, select state before you add data.").addClass("tx-danger");

        city_form.on('submit', function(e){
            e.preventDefault();

            let id = $('#country-select2').val()[0];
            let state_id = $('#state-select2').val()[0];
            let name = $('#city_name').val();

            let data = {id, state_id, name};

            $("#city_form button").text("Please Wait").prop("disabled", true).prepend('<span class="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>');
            city("post", data).then((result) => {
                if(!result.error) {
                    $("[data-toggle='popover']").popover('hide');
                    cogoToast.success(result.payload, {position: "top-right", hideAfter: 5});
                }
            });
        });
    }

    const dismissPopover = (e) => {
        $('[data-toggle="popover"]').each(function () {
            if (!$(this).is(e.target) &&
                $(this).has(e.target).length === 0 &&
                $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });
    }

    const reloadTable = () => {
        $('#region').DataTable().ajax.reload(null, false);
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="pages/authorized/settings/Region#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Region</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Region</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Region List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="region" className="table hover display" cellSpacing="0" style={{width:"100%"}}>
                                    <thead>
                                        <tr>
                                            <th className="wd-20p">Suburb/Area</th>
                                            <th className="wd-10p">City</th>
                                            <th className="wd-10p">State/Province</th>
                                            <th className="wd-10p">Country</th>
                                            <th className="wd-10p">Actions</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="modalRegion" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="pages/authorized/settings/Region#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="pages/authorized/settings/Region" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalRegion", "region_form")} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Region" : "Add Region"}</h4><p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} user to have access to the console</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="region_form" className="parsley-style-1" onSubmit={handleSubmit.bind()}>
                                        <div id="countryWrapper" className="form-group parsley-input">
                                            <div className="d-flex justify-content-between">
                                                <label>Country<span className="tx-danger">*</span></label>
                                            </div>
                                            <select className="form-control" id="country-select2" data-width="100%" multiple="multiple"
                                                    data-parsley-class-handler="#countryWrapper"
                                                    data-parsley-errors-container="#countryWrapper"
                                                    autoComplete="off"
                                                    required>
                                            </select>
                                        </div>

                                        <div id="stateWrapper" className="form-group parsley-input">
                                            <div className="d-flex justify-content-between">
                                                <label>State/Province<span className="tx-danger">*</span></label>
                                                <a tabIndex={0}
                                                   onClick={handleStateSubmit.bind()}
                                                   className="editable"
                                                   data-toggle="popover"
                                                   data-container="body"
                                                   data-title="Add state"
                                                   data-content="<form id='state_form'>
                                                                  <div class='form-group parsley-input'>
                                                                    <label for='state'>Name</label>
                                                                    <input type='text' class='form-control' id='state_name' width='70' aria-describedby='helpTip' placeholder='Enter state name' required>
                                                                    <small id='helpTip' className='form-text text-muted'>Please, select country before you add new one.</small>
                                                                  </div>
                                                                  <button type='submit' class='btn btn-block btn-primary'>Submit</button>
                                                                </form>">
                                                    Add state?
                                                </a>
                                            </div>
                                            <select className="form-control" id="state-select2" data-width="100%" multiple="multiple"
                                                    data-parsley-class-handler="#stateWrapper"
                                                    data-parsley-errors-container="#stateWrapper"
                                                    autoComplete="off"
                                                    required>
                                            </select>
                                        </div>

                                        <div id="cityWrapper" className="form-group parsley-input">
                                            <div className="d-flex justify-content-between">
                                                <label>City<span className="tx-danger">*</span></label>
                                                <a tabIndex={0}
                                                   onClick={handleCitySubmit.bind()}
                                                   className="editable"
                                                   data-toggle="popover"
                                                   data-container="body"
                                                   data-title="Add City"
                                                   data-content="<form id='city_form'>
                                                                  <div class='form-group parsley-input'>
                                                                    <label for='city_name'>Name</label>
                                                                    <input type='text' class='form-control' id='city_name' width='70' aria-describedby='helpTip' placeholder='Enter city name' required>
                                                                    <small id='helpTip' className='form-text text-muted'>Please, select state before you add new one.</small>
                                                                  </div>
                                                                  <button type='submit' class='btn btn-block btn-primary'>Submit</button>
                                                                </form>">
                                                    Add City?
                                                </a>
                                            </div>
                                            <select className="form-control" id="city-select2" data-width="100%"
                                                    multiple="multiple"
                                                    data-parsley-class-handler="#cityWrapper"
                                                    data-parsley-errors-container="#cityWrapper"
                                                    autoComplete="off"
                                                    required>
                                            </select>
                                        </div>

                                        <div id="suburbWrapper" className="form-group parsley-input">
                                            <label>Suburb/Area<span className="tx-danger">*</span></label>
                                            <input id="suburb" type="text" className="form-control"
                                                   placeholder="Enter suburb or area"
                                                   autoComplete="off"
                                                   data-parsley-class-handler="#suburbWrapper"
                                                   required
                                            />
                                        </div>

                                        {stateReady ? <button disabled className="btn btn-success btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : editForm ? <button className="btn btn-success btn-block">Update Region</button> : <button className="btn btn-success btn-block">Add Region</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content tx-14">
                                <div className="modal-header">
                                    <h6 className="modal-title">Delete Confirm?</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="mg-b-0 data-message">Empty</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" id="option-suburb" className="btn btn-success btn-block">Yes, Proceed</button>
                                    <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Region;
