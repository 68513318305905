import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
    name: "app",
    initialState: {
        territories: [],
        collectors: [],
        collectorPositions: null,
        settings: {},
        collectorCount: 0
    },
    reducers: {
        //actions => action handlers
        SET_TERRITORIES: (state, action) => {
            return {
                ...state,
                territories: action.payload.territories
            };
        },
        SET_COLLECTOR_COUNT: (state, action) => {
            return {
                ...state,
                collectorCount: action.payload.collectorCount
            };
        },
        SET_COLLECTORS: (state, action) => {
            return {
                ...state,
                collectors: action.payload.collectors
            };
        },
        SET_COLLECTOR_POSITIONS: (state, action) => {
            return {
                ...state,
                collectorPositions: action.payload.collectorPositions
            };
        },
        ADD_COLLECTOR: (state, action) => {
            return {
                ...state,
                collectors: [...state.collectors, action.payload.collectorPositions],
            };
        },
        REMOVE_COLLECTOR: (state, action) => {
            return {
                ...state,
                products: state.products.map(product =>
                    product._id === action.payload._id
                        ? {
                            ...product,
                            quantity: product.quantity !== 1 ? product.quantity - 1 : 1,
                        }
                        : product,
                ),
            };
        }
    }
});

export const {SET_TERRITORIES, SET_COLLECTOR_COUNT, SET_COLLECTOR_POSITIONS, SET_COLLECTORS} = slice.actions;
export default slice.reducer;
